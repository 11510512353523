import './App.css';
import FlashcardContainer from "./components/FlashcardContainer";
import React from "react";
import FlashcardData from "./data/vietnamese/FlashcardData";

function App() {

    return (
        <FlashcardContainer flashcardData={FlashcardData}/>
    );
}



export default App;
