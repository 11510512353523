//todo: update to enum
// LETTER, VOWEL, CONSONANT, PHONETIC, WORD, PHRASE

//TODO: can the items have a common interface?

//todo: add slowed down audio option?

const vietAlphabet = [
    {
        id: 1,
        displayText: 'a',
        soundFile: 'a.mp3',
        notes: 'These are notes',
        filterTags: ['LETTER', 'VOWEL']
    },
    {
        id: 2,
        displayText: 'ă',
        soundFile: 'a.mp3',
        notes: 'These are notes',
        filterTags: ['LETTER', 'VOWEL']
    },
    {
        id: 3,
        displayText: 'â',
        soundFile: 'a.mp3',
        notes: 'These are notes',
        filterTags: ['LETTER', 'VOWEL']
    }
]

const vietPhonetics = [

    {
        id: 4,
        displayText: 'ung',
        soundFile: 'b.mp3',
        notes: 'These are notes',
        filterTags: ['PHONETIC']
    }
]

const vietWords = [
    {
        id: 5,
        displayText: 'bánh mì',
        soundFile: 'b.mp3',
        notes: 'These are notes',
        filterTags: ['WORD']
    }
]

const vietPhrases = [
    {
        id: 6,
        displayText: 'Bạn khỏe không?',
        soundFile: 'b.mp3',
        notes: 'These are notes',
        filterTags: ['PHRASE']
    }
]

export const FlashcardData = [
    ...vietAlphabet, ...vietWords, ...vietPhonetics, ...vietPhrases
]


export default FlashcardData;
