import React, {useState} from 'react';
import classes from "./Flashcard.module.css";

const Flashcard = props=> {

    const [flip, setFlip] = useState(false)

    const flipHandler = props => {
        setFlip(!flip);
    }

    const flashcardClasses = `${classes.card} ${flip ? classes.flip : ''}`

    return (
        <div className={flashcardClasses} onClick={flipHandler}>
            <div className={classes.front}>
                {props.flashcard.displayText}
            </div>
            <div className={classes.back}>
                {props.flashcard.notes}
            </div>
        </div>
    );
};

export default Flashcard;
