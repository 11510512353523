import React, {useEffect, useState} from 'react';
import classes from "./FlashcardContainer.module.css";
import Flashcard from "./Flashcard";
import FlashcardList from "./FlashcardList";

function FlashcardContainer(props) {

    //todo: redo these useState as useEffect?
    const [soundFile, setSoundFile] = useState(null);

    const [nextCards, setNextCards] = useState(props.flashcardData);
    const [seenCards, setSeenCards] = useState([]);
    const [currentCard, setCurrentCard] = useState(0);

    useEffect(() => {
        const file = require('../audio/' + nextCards[currentCard].soundFile)
        setSoundFile(file);
    }, [currentCard]);

    const playHandler = props => {
        let audio = new Audio(soundFile);
        audio.play();
    }

    const currentCardNextHandler = props => {
        setSeenCards({...seenCards, currentCard})
        if (currentCard === nextCards.length - 1) {
            setCurrentCard(0);
        } else {
            setCurrentCard(currentCard + 1);
        }
    }

    const currentCardPreviousHandler = props => {
        if (currentCard === 0) {
            setCurrentCard(nextCards.length - 1);
        } else {
            setCurrentCard(currentCard - 1);
        }
    }

    return (
        <div className={classes.main}>
            {/*{seenCards.length ? <FlashcardList flashcards={seenCards}/> : ''}*/}
            <div>
                <Flashcard flashcard={nextCards[currentCard]} key={nextCards[currentCard].id}/>
                <div>
                    <button className={classes.button} onClick={currentCardPreviousHandler}>Prev</button>
                    <button className={classes.button} onClick={playHandler}>Audio</button>
                    <button className={classes.button} onClick={currentCardNextHandler}>Next</button>
                </div>
            </div>
            {/*<FlashcardList flashcards={nextCards}/>*/}
        </div>
    );
}

export default FlashcardContainer;
